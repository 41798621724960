import { FunctionComponent, ReactElement } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CityScienceSummit from "pages/cityScienceSummit";
import IntershipsAvaluation from 'pages/intershipsAvaluation';

type Props = {}

export const RoutesAdmin: FunctionComponent<Props> = (): ReactElement => {
  return(
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route path="/" element={<CityScienceSummit />} />
            <Route path="/:t" element={<CityScienceSummit />} />
            <Route path="interships-avaluation" element={<IntershipsAvaluation />} />
          </Route>
        </Routes>
      </BrowserRouter>  
    </div>
  )
}