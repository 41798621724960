import { ARIModals } from "components/modal"
import { MODALS } from "models/ui"
import { FunctionComponent, ReactNode } from "react"

import { Button, Image, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from "config/store/hooks"
import { ModalAction } from "modules/ui/actions"
import { selectCurrentMetadata } from "modules/ui/selector"

const {Title, Paragraph} = Typography

type Props = {
    children?: ReactNode
}

export const ModalRegistrationConfirm: FunctionComponent<Props> = ({children}) => {
  const dispatch = useAppDispatch();
  const metadata = useAppSelector(selectCurrentMetadata)

  console.log(metadata)
  return(
    <ARIModals name={MODALS.REGISTRATION_CONFIRM}>
      <div style={{alignItems: 'Center', justifyContent: 'center', display:'flex'}}>
        <Image
          src={"jpeg/check.png"}
          width={'50px'}
        />
        <Title level={2} style={{'marginLeft': '3%'}}><strong>AWESOME !</strong>  </Title>
      </div>
      <Paragraph style={{color: 'grey'}}>Your registration has been completed successfully! You will receive an e-mail soon with the confirmation of registration</Paragraph>
      {metadata.invitationalLetter && (
        <>
          <div style={{marginTop: '3%'}}>
            <Paragraph style={{color: 'grey'}}> If you wish, you can download the Invitation to the City Science Summit 2024 below </Paragraph>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={() => {
              dispatch(ModalAction.openModal({
                name: MODALS.INVITATIONAL_LETTER,
              }))}}> Download Invitational Letter </Button>
          </div>
        </>
      )}
    </ARIModals>
  )
  }