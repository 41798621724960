
import { ReactElement, FunctionComponent, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import _ from 'lodash'
import countries from 'resources/contry.json'
import { Select, Typography, message } from "antd";

const { Text, Paragraph } = Typography
type Props = {
  name: string
  items: itemsType[]
  label?: string
  placeHolder?: string
  description?: string
  children?: ReactNode
  alertMessage?: boolean
  mandatory?: boolean
  onChange?: (value?: any) => void;
};

//TODO: Migrar a archivo de tipos
type itemsType = {
  label: string,
  value: string,
}

export const ARIFieldSelect: FunctionComponent<Props> = ({
  name,
  label,
  description,
  items,
  placeHolder,
  alertMessage,
  mandatory,
  ...props
}): ReactElement => {
  const { control } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur } }) => (
          <div style={{marginTop: '2%'}}>
            <Text><strong>{`${label}`}{mandatory && (<Text> * </Text>)}</strong></Text>
            {description && (<Paragraph style={{color:'grey'}}> {`${description}`} </Paragraph>)}
            <div>
              <Select
                //labelInValue
                filterOption={false}
                variant="filled"
                style={{ width: '100%' }}
                placeholder={placeHolder && placeHolder}
                onChange={value => {
                  value && 
                    onChange(value)
                    props.onChange && props.onChange(value)

                  alertMessage &&
                    _.keyBy(countries, 'iso3')[value].visaNedded &&
                      messageApi.open({
                        type: 'warning',
                        content: `Attention ! People from ${_.keyBy(countries, 'iso3')[value].nameEN} require a VISA to access the Shengen space`
                      });
                }}
                options={items}
              />
            </div>
          </div>
        )}
      />
      {contextHolder}
    </>
  );
};
