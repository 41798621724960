
import { useState } from "react";
import { FunctionComponent, ReactElement, ReactNode } from "react";
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
 
import { Form } from "./../../../components/form";
import { Button, Card, Col, Divider, Row, Segmented, Typography } from "antd";
import { ARIFielRadioButtons } from "components/form/ariFieldRadioButtons";
import { ARIFieldInput } from "components/form/ariFieldInput";
import { ARIFieldPhoneNumber } from "components/form/ariFieldPhoneNumber";

import { InfoCircleOutlined } from '@ant-design/icons';

import useContries from 'hooks/useContries'
import useOptions from "hooks/useOptions"
import useCitySience from "hooks/cityScienceSummit/useCityScience"
import activities from 'resources/cityScienceSummit/activities.json'
import workshops from 'resources/cityScienceSummit/workshops.json'
import hotels from 'resources/cityScienceSummit/hotels.json'
import { ARIFieldSelect } from "components/form/ariFieldSelect";
import { ARIFieldDatePicker } from "components/form/ariFieldDatePicker";
import { useAppDispatch } from "config/store/hooks";
import { ModalAction } from "modules/ui/actions";
import { MODALS } from "models/ui";
import Meta from "antd/es/card/Meta";
import styled from "styled-components";
import { AppAction } from "modules/app/actions";

const { Text, Paragraph } = Typography

type Props = {
  children?: ReactNode;
};

const validationSchema = Yup.object().shape({
  assistanceForm: Yup.boolean().required('This field is required'),
  guestPhotoAutorization: Yup.boolean().required('This field is required'),
  visaNedded: Yup.boolean().required('This field is required'),
  personalInformation: Yup.object({
    name: Yup.string().required('Name is required' ),
    surname: Yup.string().required('Surname is required'),
    dateOfBirth: Yup.string(),
    phoneNumber: Yup.string().required('Phone number is required'),
    foodPreference: Yup.string(),
    passport: Yup.object().shape({
      number: Yup.string(),
      expirationDate: Yup.string(),
      countryDocumentEmissor: Yup.string()
    }),
    email: Yup.object().shape({
      primary: Yup.string().email().required('Primary mail is required'),
      secondary: Yup.string().email()
    }).required(),
    job: Yup.object().shape({
      jobTitle: Yup.string(),
      cityScienceMember: Yup.string(), 
      organizationName: Yup.string(),
    })
  })
})

export const FormGeneric: FunctionComponent<Props> = (props): ReactElement => {
	const disaptch = useAppDispatch()
	
	const { countriesList } = useContries()
  	const { yesNoOptions } = useOptions()
  	const { labsList, hotelsList } = useCitySience()
	
	const [segmentSelected, setSegmentSelected] = useState<string>('Tuesday 15')
	const [cityScienceMember, setCityScienceMember] = useState<boolean>(true)
	const [invitationalLetter, setInvitationalLetter] = useState<boolean>(true)
	const [guestAllergies, setGuestAllergies] = useState<boolean>(true)
	const [guestTravel, setGuestTravel] = useState<boolean>(true)
	const [assistance, setAssistance] = useState<any>({
		workShops: {
			workshopOne: false,
			workshopTwo: false,
			workshopTrhee: false
		},
		activities: {
			culture: false,
			adventure: false,
			relax: false,
		}
	})

	const _handleSubmit = (values:any) => {
		disaptch(AppAction.showLoading())
    	const formInformation = {...values, ...assistance}
		//TODO: serraCarles: Create a isolate service for that -> ClickUp: 86c01489y
		const data = {
			"subject": "City Science Summit",
			"name": formInformation.personalInformation.name,
			"surname": formInformation.personalInformation.surname,
			"email": formInformation.personalInformation.email.primary,
			"aditionalEmail": formInformation.personalInformation.email.secondary ? formInformation.personalInformation.email.secondary : 'No data provaider',
			"mobilePhone": formInformation.personalInformation.phoneNumber,
			"organization": formInformation.personalInformation.job.organizationName,
			"job": formInformation.personalInformation.job.jobTitle,
			"foodPreference": formInformation.personalInformation.foodPreference,
			"dateArrival": formInformation.travel.dateArrival,
			"hotel": formInformation.travel.hotel,
			"workshopOne": formInformation.workShops.workshopOne,
			"workshopTwo": formInformation.workShops.workshopTwo,
			"workshopTrhee": formInformation.workShops.workshopTrhee,
			"culture": formInformation.activities.workshopOne,
			"adventure": formInformation.activities.workshopTwo,
			"relax": formInformation.activities.workshopTrhee,
			"formType": 'Virtual o presencial, a escollir pel convidat' 
		}
		emailjs.send(
			'service_jbk9zkg',
			'template_kesv3of',
			{
				'surname': formInformation.personalInformation.surname,
				'host': formInformation.personalInformation.email.primary,
				'cc': 'cslandorra@ari.ad'
			},
			'KNo13x7wdpZ2gY9Ro'
		).then(
			(result) => {
				emailjs.send(
					'service_jbk9zkg',
					'template_nmmkmmi',
					data,
					'KNo13x7wdpZ2gY9Ro'
				).then(
					(result) => {
						disaptch(ModalAction.openModal({
							name: MODALS.REGISTRATION_CONFIRM,
							metadata: {
								name:  formInformation.personalInformation.name,
								surname: formInformation.personalInformation.surname,
								birth: formInformation.personalInformation.dateOfBirth ? formInformation.personalInformation.dateOfBirth : undefined,
								passportNumber: formInformation.personalInformation.passport.number ?  formInformation.personalInformation.passport.number : undefined,
								passportExpirationDate: formInformation.personalInformation.passport.expirationDate ?  formInformation.personalInformation.passport.expirationDate : undefined,
								country: formInformation.personalInformation.passport.countryDocumentEmissor ?  formInformation.personalInformation.passport.countryDocumentEmissor : undefined,
								invitationalLetter: invitationalLetter

							}
						}))
						disaptch(AppAction.hideLoading())
					}, 
					(error) => {		
						disaptch(ModalAction.openModal({name: MODALS.REGISTRATION_ERROR}))
						disaptch(AppAction.hideLoading()
				)})
			}, 
			(error) => {
				disaptch(ModalAction.openModal({name: MODALS.REGISTRATION_ERROR}))
				disaptch(AppAction.hideLoading())
			}
		) 
  }
  
  const toggleWorkshop = (workshop: string) => { 
    setAssistance((prevState: any) => ({
      ...prevState,
      workShops: {
        ...prevState.workShops,
        [workshop]: !prevState.workShops[workshop]
      }
    }))
  }

  const toggleActivities = (activity: string) => {
    setAssistance((prevState: any) => {
      const newActivitiesState = Object.keys(prevState.activities).reduce((acc, key) => {
        acc[key] = false
        return acc;
      }, {} as any)
      
      return {
        ...prevState, 
        activities: {
          ...newActivitiesState,
          [activity]: !prevState.activities[activity]
        }
      }
    })
  }

  return(
    <Form
      handleSubmit={_handleSubmit}
      validationSchema={validationSchema}
    >
        {/* SECTION 1:  PROFILE INFORMATION*/}
        <div>
			<Row>
				<Col span={14}>
					<Text style={{ fontSize: "large" }}><strong>Profile Information Attendance</strong></Text>
					{/* <Paragraph style={{color:"grey"}}> [If is necesary, put some description here] </Paragraph> */}
				</Col>
				<Col span={10}>
					<Col xs={24} sm={24}>
						<ARIFielRadioButtons
							name='assistanceForm'
							label='Will you be attending in-person or virtually?'
							defaultValue={true}
							items={[
								{
									"label": 'In Person',
									"value": true
								},
								{
									"label": 'Virtually',
									"value": false
								}
							]}
						/>
					</Col> 
				</Col>
			</Row>            
			<Divider/>
			<div style={{paddingLeft: '1rem'}}>
				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<ARIFieldInput
							name='personalInformation.name'
							label='First Name'
							placeholder='John'
							mandatory={true}
						/>
					</Col>
					<Col xs={24} sm={12}>
						<ARIFieldInput
							name='personalInformation.surname'
							label='Last Name'
							placeholder='Due'
							mandatory={true}
						/>
					</Col>
					<Col xs={24} sm={12}>
						<ARIFieldInput
							name='personalInformation.job.jobTitle'
							label='Job Title'
							placeholder='Developer'
						/>
					</Col> 
					<Col xs={24} sm={12}>
						<ARIFieldPhoneNumber 
							name='personalInformation.phoneNumber'
							label='Mobile'
							mandatory={true}
						/>
					</Col>
					<Col xs={24} sm={12}> 
						<ARIFielRadioButtons
							name='personalInformation.job.cityScienceMember'
							label='Are you a member of a City Science Lab?'
							defaultValue={cityScienceMember}
							items={yesNoOptions()}
							onChange={(e) => {setCityScienceMember(e.target.value)}}
						/>
					</Col>
					<Col xs={24} sm={12}> 
						{cityScienceMember
							? (
								<ARIFieldSelect 
									name='personalInformation.job.organizationName'
									label='City Science Lab'
									placeHolder='CSL @Andorra'
									items={labsList()}
								/>
							)
							: (
								<ARIFieldInput
									name='personalInformation.job.organizationName'
									label='Organization'
									placeholder='Andorra Research + Innovation'
								/>
							)
						}
					</Col>
					<Col xs={24} sm={24}>
						<ARIFielRadioButtons
							name='visaNedded'
							label='Do you need an invitation letter?'
							description='If you wish, we can provide you a letter signed by the director of the Andorra Research and Innovation to process your VISA in order to enter to the Schengen area.'
							items={yesNoOptions()}
							defaultValue={invitationalLetter}
							onChange={(e) => {setInvitationalLetter(e.target.value)} }
						/> 
					</Col>
					{invitationalLetter && (
						<>
							<Col xs={12} sm={12}>
								<ARIFieldInput
									name='personalInformation.passport.number'
									label='Passport Number'
									placeholder='00000P'
									mandatory={true}
								/>
							</Col>
							<Col xs={12} sm={12}>
								<ARIFieldDatePicker
									name='personalInformation.passport.expirationDate'
									label='Passport Expiration Date'
									mandatory={true}
								/>
							</Col>
							<Col xs={24} sm={12}>
								<ARIFieldSelect
									name="personalInformation.passport.countryDocumentEmissor"
									label="Nationality"
									placeHolder="Andorra"
									alertMessage={true}
									items={countriesList()}
									mandatory={true}
								/>
							</Col>
							<Col xs={12} sm={12}>
								<ARIFieldDatePicker
									name='personalInformation.dateOfBirth'
									label="Date of Birth"
								/>
							</Col>
						</>
					)}
				</Row>
				<br/>
				<Row gutter={16}>
					<Col xs={24} sm={12}>
						<ARIFieldInput
							name='personalInformation.email.primary'
							description="This will be used for all official City Science communications"
							label='Email'
							placeholder='john@due.com'
							mandatory={true}
						/>
					</Col>
					<Col xs={24} sm={12}>
						<ARIFieldInput
							name='personalInformation.email.secondary'
							description="This will be copied on all official City Science communications. If none, leave blank"
							label='Participant Email'
							placeholder='john@sister.com'
						/>
					</Col>    
					<Col xs={24} sm={12}>
						<ARIFielRadioButtons
							name='personalInformation.foodPreference'
							label='Do you have any food preferences or allergies?'
							items={yesNoOptions()}
							defaultValue={guestAllergies}
							onChange={(e) => {setGuestAllergies(e.target.value)} }
						/>
					</Col>
					{ guestAllergies && (
						<Col xs={24} sm={12}>
							<ARIFieldInput
								name="personalInformation.foodPreference"
								label="List preferences or allergies"
							/>
						</Col>
					)}
				</Row> 
				<Row>
					<Col xs={24} sm={24}>
						<ARIFielRadioButtons 
							name='guestPhotoAutorization'
							description="Marking yes below authorizes ARI to take and share your photo at the event your contact information with fellow Summit attendees (will not be shared externally. To decline to share your photo, mark no.)"
							label='Photo Release'
							mandatory={true}
							items={yesNoOptions()}
						/>
					</Col>
				</Row>
			</div>
		</div>

		{/* SECTION 2:  ADMINISTRATION INFORMATION*/}
		<div style={{paddingTop:'5%'}}>
			<Text><strong>Administration Information</strong></Text>
			<Divider style={{marginTop:'-1%'}}/>
			<div style={{paddingLeft: '1rem'}}>
				<Row>
					<Col xs={24} sm={16}>
						<Text><strong>Traveler Information</strong> <InfoCircleOutlined onClick={() => {disaptch(ModalAction.openModal({name: MODALS.TRAVELER_INFORMATION}))}}/></Text>
						{/* <Paragraph style={{color: 'grey'}}> Important! [Aqui un text molt clar que els recordi de revisar la seva visa]</Paragraph> */}
					</Col>
					<Col xs={24} sm={8}>
						<ARIFielRadioButtons
							name='travel.organized'
							label='You have the trip organized?'
							items={yesNoOptions()}
							defaultValue={guestTravel}
							onChange={(e) => {setGuestTravel(e.target.value)} }
						/> 
					</Col>
				</Row>
				
				{/* Travel Information */}
				<div style={{paddingLeft: '1rem'}}>
					{guestTravel ? (
						<Row gutter={16}>
							<Col xs={24} sm={12}>
								<ARIFieldDatePicker 
									name="travel.dateArrival"
									label="Date Arrival"
									placeHolder="2024/10/16"
									mandatory={true}
								/>
							</Col>

							<Col sm={12} xs={24}>
								<ARIFieldSelect 
									name="travel.hotel"
									label="Hotel"
									placeHolder="Art Hotel Andorra"
									items={hotelsList()}
								/>
							</Col>
						</Row>
					):(
						<>
							<Text> Remeber that you can book your stay in one of our Hotels and enjoy some discounts! </Text>
							<Row gutter={16}>
								{hotels.map(h => (
									<Col xs={24} sm={8}>
										<Card
											hoverable
										>
											<Meta 
												title={h.name} 
												description={h.discount.code 
													? (`Code: ${h.discount.code}`)
													: (`Email: ${h.discount.email}`)
												}
											/>
										</Card>
									</Col>
								))}
							</Row>
						</>
					)}
				</div>
			</div>
			<br/>
			<div style={{paddingLeft: '1rem', paddingRight:'1rem'}}>
				<Text><strong>Schedule</strong> <InfoCircleOutlined onClick={() => {disaptch(ModalAction.openModal({name: MODALS.SCHEDULE}))}}/></Text>
				<Paragraph style={{color: 'grey'}}> Select the activities you will attend. ⚠Below you will see a day selector, the information on the activities is dynamic depending on the day you select, so we recommend you browse through all the days to verify all the event information (Tuesday 15, Wednesday 16 and Thursday 17) </Paragraph>
				{/* <EventActivities assistation={assistance} onSelect={_handelSelectActivity('d')}/> */}
				{/* TODO: serraCarles: Try to sepearete this section. Atention to hook  */}
				<Segmented 
					options={['Tuesday 15', 'Wednesday 16', 'Thursday 17']} 
					onChange={(e) => {setSegmentSelected(e)}} 
					block 
				/>
				<div style={{paddingTop: '1%'}}>
					{segmentSelected === 'Tuesday 15'
						? (
							<div style={{paddingLeft:'1rem'}}>
								<ARIFielRadioButtons 
									name='firstDay'
									label='First day attendance'
									description="Will you participate to the first day events ?"
									items={yesNoOptions()}
								/>
							</div>
						) 
						: segmentSelected === 'Wednesday 16'
							? (
								<div style={{paddingLeft:'1rem'}}>
									<ARIFielRadioButtons
										name='secondDay.dinner'
										label='Confirm your attendance at the dinner?'
										items={yesNoOptions()}
									/>
									<br/>
									<Text><strong>WORKSHOPS</strong></Text>
									<Row gutter={5}> 
										{workshops.map(v=>
											(
											<Col sm={8} xs={24}>
												<DivStyled
													borderColor={assistance.workShops[v.key] ? 'green' : '#f0f0f0'}
													onClick={() => toggleWorkshop(v.key)}
												>
													<div style={{textAlign: 'center', marginTop: '3%'}}>
														<Text>
															<strong>{v.name}</strong>
														</Text>
													</div>
													{v.description && (<Paragraph style={{color: 'grey', textAlign:'center', display:'felx',fontSize:'12px', marginTop: '3%'}}>{v.description}</Paragraph>)}
													{/* <div style={{paddingLeft: '1rem', paddingBottom: '10px'}}>
														<div><p><EnvironmentOutlined/><strong> Place: </strong>UNSQUARE</p></div>
														<div style={{marginTop: '-5px'}}><p><CalendarOutlined /><strong> Date: </strong>{v.dateTime}</p></div>
													</div> */}
												</DivStyled>
											</Col>
											)
										)}
									</Row>
								</div>)
							: (
								//FREE ACTIVITIES
								<Row gutter={5}>
									{activities.map(a => (
										<Col sm={8} xs={24}>
					<DivActivityStyled
					borderColor={assistance.activities[a.key] ? 'green' : '#ffffff'}
					>
					<Card
						onClick={()=> {toggleActivities(a.key)}}
						cover={
						<img
							alt="example"
							src={a.img}
						/>
						}
					>
						<Meta 
						title={a.name}
						description={a.description}
						/>
					</Card>
					</DivActivityStyled>
										</Col>
									))}
								</Row>
							) 
					}
				</div>
			</div>
		</div>

		{/* SECTION 3: LEGAL INFORMATION AND SUBMIT */}
		<div style={{marginTop: '5%'}}>
			<Paragraph style={{color: 'grey'}}>
				We will use this data to respond to your inquiry or request. 
				We do not share them with third parties, and in our <a href='https://www.ari.ad/politica-privadesa'>privacy policy</a> you will find additional information about this treatment and how you can exercise your rights of access, rectification and deletion, among others.
				By sending us the form, you accept the terms and conditions of our <a href='https://www.ari.ad/avis-legal'>legal notice</a>.
			</Paragraph>
			<br/>
			<Button htmlType="submit"> Send </Button>
		</div>
    </Form>
  )
}

const DivStyled = styled.div<{borderColor?: string}>`
  border: solid;
  border-radius: 5px;
  border-color: ${props => props.borderColor || '#f0f0f0'};
  box-sizing: border-box;
  height: 230px
`;

const DivActivityStyled = styled.div<{borderColor?: string}>`
  border: solid;
  border-radius: 5px;
  border-color: ${props => props.borderColor || '#f0f0f0'};
`;