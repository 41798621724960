import { useEffect, useState } from 'react';
import { Steps } from 'antd'

import { Page } from "components/layout";
import FormStepOne from './form/formStepOne'
import { FormStepTrhee } from './form/formStepTrhee';
import { FormStepTwo } from './form/formStepTwo';

import usePlatform from 'hooks/usePlataform'



function IntershipsAvaluation() {
  const isMobile = usePlatform();
  const [step, setStep] = useState(0);
  // const [dataOne, setDataOne] = useState<any>()

  useEffect(() => {console.log(step)}, [step])
  const nextStep = (values: any) => {
    console.log(values)
    setStep(step+1)
  };
  
  const previousStep = () => {
    setStep(step-1)
  }

  return(
    <Page wallpaper="ARIWallpaper.jpg">
      <div className="form-container">
        <div style={{paddingBottom: '5%'}}>
          {!isMobile && (
            <Steps
              size="small"
              current={step}
              items={[
                {
                  title: 'Informació General',
                },
                {
                  title: 'Avaluació',
                },
                {
                  title: 'Comentaris',
                },
              ]}
            />
          )}
        </div>
        {step === 0 && <FormStepOne next={nextStep}/>}
        {step === 1 && <FormStepTwo  next={nextStep}  previous={previousStep}/>}
        {step === 2 && <FormStepTrhee next={nextStep} previous={previousStep}/>}
      </div>
    </Page>
  )
}
export default IntershipsAvaluation