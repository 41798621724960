import * as Yup from 'yup';
import {Form} from "../../../components/form"
import { Button, Col, Row} from 'antd'

import { ARIFieldDatePicker } from '../../../components/form/ariFieldDatePicker'
import { ARIFieldSelect } from '../../../components/form/ariFieldSelect'
import { ARIFielRadioButtons } from '../../../components/form/ariFieldRadioButtons'
import { FORMAT } from "models/ui";
import { ARIFieldTextArea } from "components/form/ariFieldTextArea";
import { ARIFieldInput } from "components/form/ariFieldInput";
import dayjs from 'dayjs'; //TODO: serraCarles: Migrar a moment con el utilsServices

import { Typography } from 'antd'

const { Paragraph, Title } = Typography

const validationSchema = Yup.object().shape({
  // age: Yup.boolean().required('El camp es obligatori'),
  // sex: Yup.string().required('El camp es obligatori'),
  // residence: Yup.boolean(),
  // residenceProblems: Yup.string(),
  // agreement: Yup.boolean(),
  // agreementProblems: Yup.string()
})

interface Props {
  next: (values: any) => void; 
}

const FormStepOne: React.FC<Props> = ({ next }) => {
  const _handleSubmit = (values: any) => {
    console.log('hola')
    next(values)
  }

  return (
    <Form
      handleSubmit={_handleSubmit}
      validationSchema={validationSchema}
    >
      <div>
        <Title level={1}> Informació Personal </Title>
        <Paragraph style={{color: 'grey'}}> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie mi leo, ut tincidunt eros ultricies eget. Maecenas dictum nibh magna, at rutrum est pellentesque non. Integer facilisis dolor in est accumsan dictum. Cras dignissim ligula non metus lacinia varius. Integer molestie, augue nec tempor vulputate, tortor massa dapibus leo, in aliquam arcu erat sed velit. Cras aliquam rhoncus massa, ac luctus tellus mattis nec. Aenean vestibulum consequat tortor, sed tincidunt quam pretium eu. Quisque cursus mollis hendrerit. </Paragraph>
        <Row gutter={16}>
          <Col sm={12} xs={24} >
            <ARIFieldDatePicker
              label='Data de neixement'
              name='age'
              placeHolder="Seleccioni una data"
              dateFormat={FORMAT.DD_MM_YYYY}
              maxDate={dayjs()}
              mandatory={true}
            />
          </Col>
          <Col sm={12} xs={24} >
            <ARIFieldSelect 
              label='Genere'
              name='sex'
              mandatory={true}
              items={[{label: 'Femení', value: 'fame'},{label: 'Masculí', value: 'male'}, {label: 'Altres', value: 'others'} ]}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop:'3%'}}>
          <Col sm={12} xs={24}>
            <ARIFielRadioButtons 
              name='residence'
              label='Ets resident al principat?'
              items={[{label: 'Sí', value: true}, {label: 'No', value: false}]}
            />
            <ARIFielRadioButtons 
              name='agreement'
              label="Existia algún tipus de conveni entre l'universitat i l'AR+I?"
              items={[{label: 'Sí', value: true}, {label: 'No', value: false}]}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ARIFieldTextArea 
              name='residenceProblems'
              label='Has tingut dificultats per realitzar trobar residencia? Quines? '
              rows={3}
            />
          </Col>
        </Row>
        <Row style={{marginTop: '3%'}}>
          <Col sm={24} xs={24}>
            <ARIFieldInput 
              name='agreementProblems'
              label="Has tingut facilitats per realitzar les pràctiques a l'AR+I?"
            />
          </Col>
        </Row>
        <div style={{marginTop: '3%', display: 'flex', justifyContent: 'flex-end'}}>
          <Button htmlType="submit" style={{width: '100px'}}> Següent </Button>
        </div>
      </div>
    </Form>
  )
}

export default FormStepOne;