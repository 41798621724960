import { ARIModals } from "components/modal";
import { MODALS } from "models/ui";
import { FunctionComponent, ReactNode } from "react";
import { Button, Col, Row, Typography } from 'antd'
import { Form } from "components/form";
import { ARIFieldInput } from "components/form/ariFieldInput";
import { useAppDispatch } from "config/store/hooks";
import { ModalAction } from "modules/ui/actions";
import * as Yup from 'yup';
import { ARIFieldDatePicker } from "components/form/ariFieldDatePicker";

type Props = {
  children?: ReactNode
}

const { Paragraph, Title } = Typography

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Camp obligtori'),
  weeklyHouer: Yup.number().required('Camp obligatori'),
  intershipsDates: Yup.object().shape({
    start: Yup.string().required('Camp obligatori'),
    end: Yup.string().required('Camp obligatori')
  }),
})

export const ModalTalentPromotionPersonalInformation: FunctionComponent<Props> = ({children}) => {
  const dispatch = useAppDispatch()

  const _handleSubmit = (value: any) => {
    dispatch(ModalAction.openModal({
      name: MODALS.TALENT_PROMOTION,
      metadata: { value },
    }))
  } 
  return(
    <ARIModals 
      name={MODALS.TALENT_PROMOTION_PERSONAL_INFORMATION}
      size="45%"
    >
      <Title> Certificat Foment del Talent </Title>
      <Paragraph style={{color: 'grey'}}>Descarrega't el certificat del Foment del Talent. Només necessitem les següents dades! Recorda que tot el formulari ha estat anònim, i per tant les seves dades personals no s'han compartit amb les respostes del formulari.</Paragraph>
      <Form
          handleSubmit={_handleSubmit}
          validationSchema={validationSchema}
        >
          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <ARIFieldInput
                name='name'
                label='Nom complet'
              />
            </Col>
            <Col sm={12} xs={24}>
              <ARIFieldInput
                name='weeklyHouer'
                label="Nombre d'Hores/setmana"
              />
            </Col>
            <Col sm={12} xs={12}>
              <ARIFieldDatePicker
                name="intershipsDates.start"
                label="Data Inici"
                placeHolder='13-05-2021'
                dateFormat="DD-MM-YYYY"
              />
            </Col>
            <Col sm={12} xs={12}>
              <ARIFieldDatePicker
                name="intershipsDates.end"
                label="Data Fi"
                placeHolder='13-05-2021'
                dateFormat="DD-MM-YYYY"
              />
            </Col>
          </Row>
          <div style={{marginTop: '5%', display:'flex', justifyContent:'flex-end'}}>
            <Row>
              <Col sm={24} xs={24}>
                <Button htmlType="submit"> Descarrgar el certificat </Button>
              </Col>
            </Row>
          </div>
      </Form>
    </ARIModals>
  )
}