
import { useState } from "react";
import { FunctionComponent, ReactElement, ReactNode } from "react";

import { Button, Col, Divider, Row, Typography } from "antd";
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';

import { Form } from "./../../../components/form";
import { ARIFielRadioButtons } from "./../../../components/form/ariFieldRadioButtons";
import { ARIFieldInput } from "./../../../components/form/ariFieldInput";
import { ARIFieldPhoneNumber } from "./../../../components/form/ariFieldPhoneNumber";
import { ARIFieldSelect } from "./../../../components/form/ariFieldSelect";

import useOptions from "hooks/useOptions"
import useCitySience from "hooks/cityScienceSummit/useCityScience"
import { ModalAction } from "modules/ui/actions";
import { MODALS } from "models/ui";
import { useAppDispatch } from "config/store/hooks";
import { AppAction } from "modules/app/actions";

const { Text, Paragraph } = Typography

type Props = {
  children?: ReactNode;
};

const validationSchema = Yup.object().shape({
  personalInformation: Yup.object({
    name: Yup.string().required('Name is required' ),
    surname: Yup.string().required('Surname is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.object().shape({
      primary: Yup.string().email().required('Primary mail is required'),
      secondary: Yup.string().email()
    }).required(),
    job: Yup.object().shape({
      jobTitle: Yup.string(),
      cityScienceMember: Yup.string(), 
      organizationName: Yup.string(),
    })
  })
})

export const FormOnlineOnly: FunctionComponent<Props> = (props): ReactElement => {
  const dispatch = useAppDispatch()
  const { yesNoOptions } = useOptions()
  const { labsList } = useCitySience()
  const [cityScienceMember, setCityScienceMember] = useState<boolean>(true)


  const _handleSubmit = (formInformation:any) => {
    dispatch(AppAction.showLoading())
    //TODO: serraCarles: Create a isolate service for that -> ClickUp: 86c01489y
    const data = {
      "subject": "City Science Summit",
      "name": formInformation.personalInformation.name,
      "surname": formInformation.personalInformation.surname,
      "email": formInformation.personalInformation.email.primary,
      "aditionalEmail": formInformation.personalInformation.email.secondary ? formInformation.personalInformation.email.secondary : 'No data provaider',
      "mobilePhone": formInformation.personalInformation.phoneNumber,
      "organization": formInformation.personalInformation.job.organizationName,
      "job": formInformation.personalInformation.job.jobTitle,
      "formType": 'Online Only' 
    }

    emailjs.send(
      'service_jbk9zkg',
      'template_nmmkmmi',
      data,
      'KNo13x7wdpZ2gY9Ro'
    ).then((result) => {
      emailjs.send(
        'service_jbk9zkg',
        'template_kesv3of',
        {
          'surname': formInformation.personalInformation.surname,
          'host': formInformation.personalInformation.email.primary,
          'cc': 'cslandorra@ari.ad'
        },
        'KNo13x7wdpZ2gY9Ro'
      ).then((result) => {
        dispatch(ModalAction.openModal({name: MODALS.REGISTRATION_CONFIRM}))
        dispatch(AppAction.hideLoading())
      }, () => {
        dispatch(ModalAction.openModal({name: MODALS.REGISTRATION_ERROR}))
        dispatch(AppAction.hideLoading())
      }) 
    }, (error) => {
      dispatch(ModalAction.openModal({name: MODALS.REGISTRATION_ERROR}))
      dispatch(AppAction.hideLoading())
    }) 
  }
  
  return(
    <Form
      handleSubmit={_handleSubmit}
      validationSchema={validationSchema}
    >
      {/* SECTION 1:  PROFILE INFORMATION*/}
      <div>
        <Text style={{ fontSize: "large" }}><strong>Profile Information Attendance</strong></Text>
        <Paragraph style={{color:'grey'}}>The registration for City Science Summit 22024 using the form below is only for participation in a virtual modality. which means that we will record your details in order to be able to contact you and facilitate the streaming link. If you are really interested in participating face to face, please contact Margaret Church.</Paragraph>
        <Divider/>
        <div style={{paddingLeft: '1rem'}}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <ARIFieldInput
                name='personalInformation.name'
                label='First Name'
                placeholder='John'
                mandatory={true}
              />
            </Col>
            <Col xs={24} sm={12}>
              <ARIFieldInput
                name='personalInformation.surname'
                label='Last Name'
                placeholder='Due'
                mandatory={true}
              />
            </Col>
            <Col xs={24} sm={12}>
              <ARIFieldInput
                name='personalInformation.job.jobTitle'
                label='Job Title'
                placeholder='Developer'
              />
            </Col> 
            <Col xs={24} sm={12}>
              <ARIFieldPhoneNumber 
                name='personalInformation.phoneNumber'
                label='Mobile'
                mandatory={true}
              />
            </Col>
            <Col xs={24} sm={12}> 
              <ARIFielRadioButtons
                name='personalInformation.job.cityScienceMember'
                label='Are you a member of a City Science Lab?'
                defaultValue={cityScienceMember}
                items={yesNoOptions()}
                onChange={(e) => {setCityScienceMember(e.target.value)}}
              />
            </Col>
            <Col xs={24} sm={12}> 
              {cityScienceMember
                ? (
                  <ARIFieldSelect 
                    name='personalInformation.job.organizationName'
                    label='City Science Lab'
                    placeHolder='CSL @Andorra'
                    items={labsList()}
                  />
                )
                : (
                  <ARIFieldInput
                    name='personalInformation.job.organizationName'
                    label='Organization'
                    placeholder='Andorra Research + Innovation'
                  />
                )
              }
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <ARIFieldInput
                name='personalInformation.email.primary'
                description="This will be used for all official City Science communications"
                label='Email'
                placeholder='john@due.com'
                mandatory={true}
              />
            </Col>
            <Col xs={24} sm={12}>
              <ARIFieldInput
                name='personalInformation.email.secondary'
                description="This will be copied on all official City Science communications. If none, leave blank"
                label='Participant Email'
                placeholder='john@sister.com'
              />
            </Col>    
          </Row> 
        </div>
      </div>

      {/* SECTION 2: LEGAL INFORMATION AND SUBMIT */}
      <div style={{paddingTop: '5%'}}>
        <Paragraph style={{color: 'grey'}}>
          We will use this data to respond to your inquiry or request. 
          We do not share them with third parties, and in our <a href='https://www.ari.ad/politica-privadesa'>privacy policy</a> you will find additional information about this treatment and how you can exercise your rights of access, rectification and deletion, among others.
          By sending us the form, you accept the terms and conditions of our <a href='https://www.ari.ad/avis-legal'>legal notice</a>.
        </Paragraph>
        <div style={{float: 'right', paddingRight: '5%'}}>
          <Button htmlType="submit" style={{width: '100px'}}> Send </Button>
        </div>
      </div>
  </Form>
  )
}
