import contries from 'resources/contry.json'

const useContries = () => {
  const countriesList = () => {
    return contries.map(c => ({
        "label": c.nameEN,
        "value": c.iso3
    }))
  }

  return {
    countriesList
  }
};

export default useContries;
