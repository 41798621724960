
import { ReactElement, FunctionComponent, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Typography } from "antd";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';

const { Text, Paragraph } = Typography
type Props = {
  name: string
  label?: string
  description?: string
  children?: ReactNode
  mandatory?: boolean
};

export const ARIFieldPhoneNumber: FunctionComponent<Props> = ({
  name,
  label,
  description,
  mandatory
}): ReactElement => {
  const { control, formState: { errors } } = useFormContext();
  const getError = (name: string, errors: any) => {
    const keys = name.split('.');
    let error = errors;
    for (const key of keys) {
      if (error[key]) {
        error = error[key];
      } else {
        return undefined;
      }
    }
    return error.message;
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <div style={{marginTop: '2%'}}>
          <span>
            {/* TODO: serraCarles: Edit Style for the input, same style than the other inputs */}
            <Text><strong>{`${label}`}</strong>{mandatory && (<Text> * </Text>)}</Text>
              {description && (<Paragraph style={{color:'grey'}}> {`${description}`} </Paragraph>)}
            <PhoneInput
              inputStyle={{
                backgroundColor: '#f5f5f5',
                border: 'none',
                width: '100%',
                borderRadius: '6px',
                lineHeight: '1.5714285714285714'
              }}
              defaultCountry="ad"
              value={value}
              onChange={value => {
                  onChange( value );
              }}
              />

          </span>
          {getError(name, errors) && <Text style={{ color: 'red' }}>{getError(name, errors)}</Text>}
        </div>
      )}
    />
  );
};
