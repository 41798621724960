import Layout, { Content} from "antd/es/layout/layout";
import { Loading } from "components/loading";
import { GlobalModals } from "pages/ui/modals";
import { FunctionComponent, ReactElement, ReactNode } from "react";
import styled from "styled-components";


type Props = {
	title?: string;
  wallpaper: string
	children?: ReactNode;
};

export const Page: FunctionComponent<Props> = (props): ReactElement => {
	return(
		<Layout>
			<GlobalModals />
      <Loading />
      <DivHeader backgroundImage={props.wallpaper}>
        {props.title &&(
          <div className="header-content">
            <h1 className="header-title">{props.title}</h1>
          </div>
        )}
      </DivHeader>
			<Content className="content">
				{props.children}
			</Content>
		</Layout>
	)
}

const DivHeader = styled.div<{backgroundImage?: string}>`
  background: url(jpeg/${props => props.backgroundImage}) no-repeat center center;
  background-size: cover;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`
