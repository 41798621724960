import { FunctionComponent, ReactElement } from "react";
import { Button, Typography } from 'antd'
import * as Yup from 'yup';

import {Form} from "../../../components/form"
import {Col, Row} from 'antd'

import { ARIFielRadioButtons } from '../../../components/form/ariFieldRadioButtons'

const { Paragraph, Title } = Typography

const validationSchema = Yup.object().shape({
  // expirience: Yup.string().required('El camp es obligatori'),
  // satisfaccion: Yup.string().required('El camp es obligatori'),
  // workEnvironment: Yup.string().required('El camp es obligatori'),
  // wellcomer: Yup.string().required('El camp es obligatori'),
  // newsSkills: Yup.string().required('El camp es obligatori'),
  // expectations: Yup.string().required('El camp es obligatori'),
  // support: Yup.string().required('El camp es obligatori'),
  // feedbacks: Yup.string().required('El camp es obligatori'),
  // reconized: Yup.string().required('El camp es obligatori'),
  // workConditions: Yup.string().required('El camp es obligatori'),
}) 

interface MiComponenteProps {
  next: (values: any) => void; 
  previous: () => void
}

export const FormStepTwo: FunctionComponent<MiComponenteProps> = ({next, previous}): ReactElement => {
  const _handleSubmit = (values: any) => {
    next(values)
  }
  
  return (
    <Form
      handleSubmit={_handleSubmit}
      validationSchema={validationSchema}
    >
      <div>
      <Title level={3}> 2. AVALUACIÓ</Title>
      <Paragraph style={{color: 'grey'}}> -OPCIONAL- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie mi leo, ut tincidunt eros ultricies eget. Maecenas dictum nibh magna, at rutrum est pellentesque non. Integer facilisis dolor in est accumsan dictum. Cras dignissim ligula non metus lacinia varius. Integer molestie, augue nec tempor vulputate, tortor massa dapibus leo, in aliquam arcu erat sed velit. Cras aliquam rhoncus massa, ac luctus tellus mattis nec. Aenean vestibulum consequat tortor, sed tincidunt quam pretium eu. Quisque cursus mollis hendrerit. </Paragraph>
        <Row gutter={16}>
          <Col sm={12} xs={24}>
            <ARIFielRadioButtons 
              name='expirience'
              mandatory={true}
              label="1. Com valoraries la teva experiència general a Andorra Recerca i Innovació?"
              items={[{
                label: 'Excel·lent',
                value:'excelent',
                image: 'veryHappy.png'
              },{
                label: 'Bona',
                value:'good',
                image: 'happy.png'
              },{
                label: 'Acceptable',
                value:'acceptable',
                image: 'neutral.png'
              },{
                label: 'Dolenta',
                value:'bad',
                image: 'sad.png'
              },{
                label: 'Molt dolenta',
                value:'veryBad',
                image: 'enojado.png'
              }]} 
            />
          </Col>
          <Col sm={12} xs={24}>
              <ARIFielRadioButtons 
                name='satisfaccion'
                mandatory={true}
                label="2. Quin va ser el teun nivell de satisfacció amb les tasques assignades?"
                items={[{
                  label: 'Molt satisfet/a',
                  value:'excelent',
                  image: 'veryHappy.png'
                },{
                  label: 'Satisfet/a',
                  value:'good',
                  image: 'happy.png'
                },{
                  label: 'Neutre',
                  value:'acceptable',
                  image: 'neutral.png'
                },{
                  label: 'Insatisfet/a',
                  value:'bad',
                  image: 'enojado.png'
                },{
                  label: 'Molt insatisfet/a',
                  value:'veryBad',
                  image: 'veryEnojado.png'
                }]} 
              />
          </Col>
     
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                name='workEnvironment'
                mandatory={true}
                label="3. Com valoraries l'ambient de treball a l'organització?"
                items={[{
                  label: 'Molt satisfet/a',
                  value: 'excelent',
                  image: 'veryHappy.png'
                },{
                  label: 'Satisfet/a',
                  value: 'good',
                  image: 'happy.png'
                },{
                  label: 'Neutre',
                  value: 'acceptable',
                  image: 'neutral.png'
                },{
                  label: 'Insatisfet/a',
                  value:'bad',
                  image: 'sad.png'
                },{
                  label: 'Molt insatisfet/a',
                  value:'veryBad',
                  image: 'veryEnojado.png'
                }]} 
              />
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                name='wellcomer'
                mandatory={true}
                label="4. Et vas sentir benvingut/da i suportat/da pel teu equip?"
                items={[{
                  label: 'Sí',
                  value:'yes'
                },{
                  label: 'No',
                  value:'no'
                },{
                  label: 'En part',
                  value:'partial'
                }]} 
              />
            </div>
          </Col>
 
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                name='newsSkills'
                mandatory={true}
                label="5. Creus que has adquirit noves habilitats i coneixements durant el període de pràctiques/treball?"
                items={[{
                  label: 'Molt',
                  value:'very',
                  image: 'like.png'
                },{
                  label: 'Bastant',
                  value:'enought',
                  image: 'mediumHigh.png'
                },{
                  label: 'Poc',
                  value:'less',
                  image: 'mediumLow.png'
                },{
                  label: 'Gens',
                  value:'nothing',
                  image: 'disLike.png'
                }]} 
              />
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div style={{marginTop:'5%'}}>
              <ARIFielRadioButtons 
                name='expectations'
                mandatory={true}
                label="6. L'experiència ha complert les teves expectatives en termes de desenvolupament professional?"
                items={[{
                  label: 'Molt',
                  value:'very',
                  image: 'like.png'
                },{
                  label: 'Bastant',
                  value:'enought',
                  image: 'mediumHigh.png'
                },{
                  label: 'Poc',
                  value:'less',
                  image: 'mediumLow.png'
                },{
                  label: 'Gens',
                  value:'nothing',
                  image: 'disLike.png'
                }]} 
              />
            </div>
          </Col>
                   
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons               
                name='support'
                mandatory={true}
                label="7. Com valoraries el suport i guia proporcionats pel teu supervisor?"
                items={[{
                  label: 'Excel·lent',
                  value:'excelent',
                  image: 'veryHappy.png'
                },{
                  label: 'Bo',
                  value:'good',
                  image: 'happy.png'
                },{
                  label: 'Acceptable',
                  value:'acceptable',
                  image: 'neutral.png'
                },{
                  label: 'Dolent',
                  value:'bad',
                  image: 'sad.png'
                },{
                  label: 'Molt dolent',
                  value:'veryBad',
                  image: 'enojado.png'
                }]} 
              />
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                name='feedbacks'
                mandatory={true}
                label="8. Has rebut feedback regular i constructiu sobre el teu treball?"
                items={[{
                  label: 'Sí',
                  value:'yes'
                },{
                  label: 'No',
                  value:'no'
                },{
                  label: 'En part',
                  value:'partial'
                }]} 
              />
            </div>
          </Col>

          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                name='reconized'
                mandatory={true}
                label="9. Et vas sentir reconegut/da pel teu treball i contribucions?"
                items={[{
                  label: 'Sí',
                  value:'yes'
                },{
                  label: 'No',
                  value:'no'
                },{
                  label: 'En part',
                  value:'partial'
                }]} 
              />
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div style={{marginTop: '5%'}}>
              <ARIFielRadioButtons 
                mandatory={true}
                name='workConditions'
                label="10. Com valoraries les condicions de treball (horari, infraestructura, recursos, material)?"
                items={[{
                  label: 'Excel·lent',
                  value:'excelent',
                  image: 'veryHappy.png'
                },{
                  label: 'Bones',
                  value:'good',
                  image: 'happy.png'
                },{
                  label: 'Acceptables',
                  value:'acceptable',
                  image: 'neutral.png'
                },{
                  label: 'Dolentes',
                  value:'bad',
                  image: 'sad.png'
                },{
                  label: 'Molt dolent',
                  value:'veryBad',
                  image: 'enojado.png'
                }]} 
              />
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: '3%'}}>
          <Col span={12}> 
            <Button onClick={previous} style={{width: '100px'}}> Anterior </Button>
          </Col>
          <Col span={12} style={{display:'flex', justifyContent: 'flex-end'}}>
            <Button htmlType="submit" style={{width: '100px'}}> Següent </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}