import { useState, useEffect } from "react";

function usePlataform() {
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        const userAgent = navigator.userAgent
        const isMobileDevice = /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(userAgent);

        const isMobileScreen = window.innerWidth <= 768 // This value is the breakpoint, that means that is ajustable

        setIsMobile(isMobileDevice || isMobileScreen)
    }, [])

    return isMobile
}

export default usePlataform