import { Page } from "components/layout";
import { FormGeneric } from './forms/generic';
import { FormOnlineOnly } from './forms/onlineOnly'

import { useParams } from "react-router";
 
function CityScienceSummit() {
  const { t } = useParams<{ t: string }>();  
  return (
    <>
      <Page title="City Science Summit Andorra - Registration -" wallpaper="wallpaper_two.jpg">
        <div className="form-container">
          {t === 'Rm9tT25MaW5lT25seVJlZ2lzdGVy'
            ? ( <FormOnlineOnly />)
            : ( <FormGeneric /> ) 
          }
        </div>
      </Page>
    </>
  )
}


export default CityScienceSummit;