import { FORMAT } from 'models/ui'
import moment from 'moment'


class UtilsService {
    public today() {
        return moment()
    }
    public formatDate(date: string, format: FORMAT): string {
        return moment(date).format(format)
    }
}

export const utilsService = new UtilsService()
