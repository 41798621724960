const useOptions = () => {
  const yesNoOptions = () => {
    return [{
      "label": 'Yes',
      "value": true
    },
    {
      "label": 'No',
      "value": false
    }]
  }
    
  return {
    yesNoOptions
  }
};

export default useOptions;
