import { ARIModals } from "components/modal"
import { MODALS } from "models/ui"
import { FunctionComponent, ReactNode } from "react"

import { Image, Typography } from 'antd'

const {Title, Paragraph} = Typography

type Props = {
    children?: ReactNode
}

export const ModalRegistrationError: FunctionComponent<Props> = ({children}) => {
  return(
    <ARIModals name={MODALS.REGISTRATION_ERROR}>
      <div style={{alignItems: 'Center', justifyContent: 'center', display:'flex'}}>
        <Image
          src={"jpeg/cross.png"}
          width={'50px'}
        />
        <Title level={2} style={{'marginLeft': '3%'}}><strong>SOMETHING GOES WRONG !</strong>  </Title>
      </div>
      <div style={{marginTop: '3%'}}>
        <Paragraph style={{color: 'grey'}}>Something has not worked properly, your registration could not be done at this time, I recommend you to try again in some instates, or send an e-mail to: <strong>cslandorra@ari.ad</strong></Paragraph>
      </div>
    </ARIModals>
  )
  }