
import { ReactElement, FunctionComponent, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Image, Radio, Typography } from "antd";
import { ItemsType } from "models/ui";

const { Text, Paragraph } = Typography
type Props = {
  name: string,
  defaultValue?: boolean | string
  items: ItemsType[]
  label?: string,
  description?: string
  style?: string
  children?: ReactNode
  mandatory?: boolean 
  onChange?: (value?: any) => void;
};

export const ARIFielRadioButtons: FunctionComponent<Props> = ({
  name,
  label,
  description, 
  items,
  defaultValue,
  mandatory,
  ...props
}): ReactElement => {
  const { control, formState: { errors }  } = useFormContext();
  const getError = (name: string, errors: any) => {
    const keys = name.split('.');
    let error = errors;
    for (const key of keys) {
      if (error[key]) {
        error = error[key];
      } else {
        return undefined;
      }
    }
    return error.message;
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <div style={{marginTop: '2%'}}>
          <Text><strong>{`${label}`}{mandatory && (<Text> *</Text>)}</strong></Text>
          {description && (<Paragraph style={{color:'grey'}}> {`${description}`} </Paragraph>)}
          <div style={{paddingLeft: '5%'}}>
            <Radio.Group
                value={value}
                onChange={value => {
                value && 
                  onChange(value)
                  props.onChange && props.onChange(value)
                  console.log(errors)
              }}
            >
              {items && items.map((v: ItemsType) => 
                v.image 
                  ? (
                    <div style={{marginBottom: "1%", marginTop: "1%"}}>  
                      <Radio value={v.value}>                  
                        <Image
                          src={`icons/${v.image}`}
                          width={'7%'}
                          height={'7%'}
                          preview={false}
                        />
                        {`  ${v.label}`}   
                      </Radio>
                    </div>) 
                  : (<Radio value={v.value}>{v.label}</Radio>)
                )
              }
            </Radio.Group>
          </div>
          {getError(name, errors) && <Text style={{ color: 'red' }}>{getError(name, errors)}</Text>}
        </div>
      )}
    />
  );
};
