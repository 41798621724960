import { ARIModals } from "components/modal";
import { MODALS } from "models/ui";
import { FunctionComponent, ReactNode } from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
import { useAppSelector } from "config/store/hooks";
import { selectCurrentMetadata } from "modules/ui/selector";

type Props = {
  children?: ReactNode
}

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden'
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex:' -1'
  },
  content: {
    position: 'relative',
    top: 40, // Ajusta la posición según sea necesario
    left: 40,
    right: 40,
    zIndex: 1,  // Asegura que el contenido esté por encima de la imagen
  },

});

const MyDocument = (metadata: any) => {
  return(
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.container}>
          <Image 
            style={styles.backgroundImage}
            src="jpeg/talentPromotionPDF.jpg" 
          />  
          <div style={{marginTop: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Text style={{color: '#394298', fontSize: '20px', fontWeight: 'bold'}}>PROGRAMA DE FOMENT DEL TALENT</Text>
            <Text style={{color: '#394298', fontSize: '16px'}}> --- CERTIFICAT DE PARTICIPACIÓ --- </Text>
            
            <div style={{marginTop: '5%'}}>
              <Text style={{fontSize: '30px', color: '#394298'}}> {metadata.metadata.value.name} </Text>
            </div>

            <div>
              <Text style={{
                fontSize: '12px', 
                marginLeft: '20%', 
                marginRight: '20%', 
                marginTop: '5%',
              }}>
                ha completat satisfactòriament el programa de pràctiques a l'Andorra Recerca i Innovació (AR+I) durant el període comprès entre {metadata.metadata.value.intershipsDates.start} i {metadata.metadata.value.intershipsDates.end}, amb una durada total de {metadata.metadata.value.weeklyHouer} Hores/Setmana
              </Text>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  )
}

export const ModalTalentPromotion: FunctionComponent<Props> = ({children}) => {
  const metadata = useAppSelector(selectCurrentMetadata)
  return(
    <ARIModals name={MODALS.TALENT_PROMOTION} drawer={{slide: 'right' }} size="75%">
      <PDFViewer width="100%" height={"100%"}>
        <MyDocument metadata={metadata}/>
      </PDFViewer>
    </ARIModals>
  )
}