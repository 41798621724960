import labs from 'resources/cityScienceSummit/labs.json'
import hotels from 'resources/cityScienceSummit/hotels.json'

const useCitySience = () => {
  const labsList = () => {
    return labs.map(c => ({
      "label": c.label,
      "value": c.value
    }))
  }

  const hotelsList = () => {
    return hotels.map(h => ({
      "label": h.name,
      "value": h.key
    }))
  }

  return {
    labsList,
    hotelsList
  }
};

export default useCitySience;