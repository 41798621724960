
import { FunctionComponent, ReactElement } from "react";
import { Typography } from 'antd';
import { Controller, useFormContext } from "react-hook-form";
import TextArea from "antd/es/input/TextArea";

const {Text, Paragraph} = Typography

type Props = {
  name: string
  label?: string
  description?: string
  value?: string
  disable?: boolean
  mandatory?: boolean
  rows?: number
  onChange?: (value?: any) => void;
  onBlur?: VoidFunction;
};
export const ARIFieldTextArea: FunctionComponent<Props> = ({
  name,
  label,
  description,
  mandatory,
  rows,
  ...props
}): ReactElement => {
  const { control, formState: { errors } } = useFormContext();
  // TODO: serraCarles: Verify lodash librery to simplify this code
  const getError = (name: string, errors: any) => {
    const keys = name.split('.');
    let error = errors;
    for (const key of keys) {
      if (error[key]) {
        error = error[key];
      } else {
        return undefined;
      }
    }
    return error.message;
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <div style={{marginTop: '2%'}}>
          <Text><strong>{`${label}`}{mandatory && (<Text> * </Text>)}</strong></Text>
          {description && (<Paragraph style={{color:'grey'}}> {`${description}`} </Paragraph>)}
            <TextArea
              disabled={props.disable}
              onBlur={onBlur}
              variant="filled"
              rows={rows}
              value={value}
              onChange={value => {
                onChange( value.target.value );
                props.onChange && props.onChange(value.target.value);
                
              }}
            />
          {getError(name, errors) && <Text style={{ color: 'red' }}>{getError(name, errors)}</Text>}
          </div>
      )}
    />
  );
};
