import { FunctionComponent, ReactElement, ReactNode } from "react"
import { ModalRegistrationConfirm } from "./cityScienceSummit/modalRegistrationConfirm";
import { ModalRegistrationError } from "./cityScienceSummit/modalRegistrationError";
import { ModalInvitationalLetter } from "./cityScienceSummit/modalInvitationalLetter";
import { ModalTalentPromotion } from "./intershipsAvaliation/talentPromotion";
import { ModalTalentPromotionPersonalInformation } from "./intershipsAvaliation/talentPromotionPersonalInformation";

type Props = {
  children?: ReactNode;
};

export const GlobalModals: FunctionComponent<Props> = ({
  children,
}): ReactElement => {
  return (
    <>
      <ModalRegistrationConfirm />
      <ModalRegistrationError />
      <ModalInvitationalLetter />
      <ModalTalentPromotion />
      <ModalTalentPromotionPersonalInformation />
    </>
  );
};
