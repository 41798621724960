
import { ARIModals } from "components/modal";
import { MODALS } from "models/ui";
import { FunctionComponent, ReactNode } from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useAppSelector } from "config/store/hooks";
import { selectCurrentMetadata } from "modules/ui/selector";

type Props = {
  children?: ReactNode
}

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Esto coloca una imagen a la izquierda y otra a la derecha
    marginBottom: 20,
  },
  regularText: {
    fontSize: 12, // Tamaño de texto normal
    color: '#000000', // Color del texto normal
    lineHeight: 1.5, // Altura de línea
    textAlign: 'justify'
  },
  signature: {
    position: 'relative',
    width: '100px'
  },
  logo: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '150px',
    marginTop: '7%',
    marginLeft: '7%'
    // height: '200px',
  },
});

const MyDocument = (metadata: any) => {
  console.log(metadata.metadata.name)
  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src="jpeg/ARIicon.png"/>
        <Image style={styles.logo} src="jpeg/letterResource.png"/>  
      </View>
      <View>
        <div style={{marginLeft: '14%', marginTop: '10%', marginRight: '10%'}}>
          <Text style={styles.regularText}>{ `Dear Mr./Mrs. ${metadata.metadata.surname} ,` }</Text>
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>Thank you for registering to the City Science Summit Andorra 2024. We are thrilled to confirm your participation to this event, hosted by the City Science Lab @Andorra and managed by Andorra Research + Innovation.</Text>    
          </div>
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>The event will take place from October 14th to 17th, 2024, in Andorra la Vella, and all the agenda details will be communicated and posted in the City Science Network website (citysciencenetwork.org). This year’s Summit will focus on the theme of Transformative Communities, and your perspective and insights will be essential as we welcome international leaders in the fields of urban science and planning, computer science, policy and decision-making, social sciences, and rapid urbanization. We are excited to welcome you, we look forward to your contributions to the discussions, and we are eager to engage in future collaborations.</Text>    
          </div>
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>Please note that this letter's purpose is to facilitate the event attendee into the process of getting the entry permit to Andorra and its neighbor countries, France and Spain, in case this would be required. Please check with your local authorities if you need a specific entry permit to Andorra, and please note that you will be travelling in transit via Spain or France.</Text>
          </div>
          <div style={{marginTop: '2%', marginLeft: '5%'}}>
            <Text style={styles.regularText}>{`First Name: ${metadata.metadata.name}`}</Text>
            <Text style={styles.regularText}>{`Last Name: ${metadata.metadata.surname}`}</Text>
            <Text style={styles.regularText}>{`Date of birth: ${metadata.metadata.birth}`}</Text>
            <Text style={styles.regularText}>{`Passport number: ${metadata.metadata.passportNumber}`}</Text>
            <Text style={styles.regularText}>{`Passport expiration date: ${metadata.metadata.passportExpirationDate}`}</Text>
            <Text style={styles.regularText}>{`Country of Origin: ${metadata.metadata.country}`}</Text>
          </div>

          <div style={{ marginLeft: '50%'}}>
            <Image 
              style={styles.signature}
              src="jpeg/VanesaSignature.png"
            />
            <Text style={styles.regularText}>Your sincerely,</Text>
            <Text style={styles.regularText}>Vanesa Arroyo</Text>
            <Text style={styles.regularText}>Director of Andorra Research + Innovation</Text>
          </div>
        </div>
      </View>
    </Page>
  </Document>
)
};

export const ModalInvitationalLetter: FunctionComponent<Props> = ({children}) => {
  const metadata = useAppSelector(selectCurrentMetadata)
  return(
    <ARIModals name={MODALS.INVITATIONAL_LETTER} drawer={{slide: 'right' }} size="75%">
      <PDFViewer width="100%" height={"100%"}>
        <MyDocument metadata={metadata}/>
      </PDFViewer>
    </ARIModals>
  )
}